function toggleNav(nav: HTMLUListElement) {
  if (window.innerWidth < 768) {
    nav.style.display = nav.style.display === "block" ? "none" : "block";
  }
}

function closeNav(nav: HTMLUListElement) {
  if (window.innerWidth < 768) {
    nav.style.display = "none";
  }
}

export function initNavToggle() {
  const toggle = document.querySelector(
    ".nav-main__toggle"
  ) as HTMLButtonElement;
  const nav = document.querySelector(".nav-main__list") as HTMLUListElement;
  const navItems = document.querySelectorAll(
    ".nav-main__item"
  ) as NodeListOf<HTMLLIElement>;

  toggle.addEventListener("click", () => {
    toggleNav(nav);
  });

  for (var i = 0; i < navItems.length; i++) {
    navItems[i].addEventListener("click", () => {
      closeNav(nav);
    });
  }
}
